import React, { useEffect, useState } from 'react';

const Players = () => {
  const [sheetData, setSheetData] = useState([]);

  useEffect(() => {
    const fetchSheetData = async () => {
      const spreadsheetId = '1F-a2Qe8TcMEOx2AX-x75m9ShrkchZEgMNF4_PBw2dZE';
      const range = 'Sheet1!A1:S10';

      try {
        const response = await fetch(
          `/api/getSheetData?spreadsheetId=${encodeURIComponent(spreadsheetId)}&range=${encodeURIComponent(range)}`
        );
        const data = await response.json();
        setSheetData(data.values);
      } catch (error) {
        console.error('Error fetching sheet data:', error);
      }
    };

    fetchSheetData();
  }, []);

  return (
    <div>
      <table>
        <thead>
          <tr>
            {/* Vous pouvez ajouter des en-têtes de colonnes ici si vous le souhaitez */}
          </tr>
        </thead>
        <tbody>
          {sheetData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Players;
