import React from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Guilde Logo" className="sidebar-logo" />
      <div className="sidebar-nav">
        {/* Utilisez le composant Link pour la navigation */}
        <Link to="/" className="sidebar-button">
          Home
        </Link>
        <Link to="/players" className="sidebar-button">
          Players
        </Link>
        <a href="#recruitment" className="sidebar-button">
          Recruitment
        </a>
        <a href="#about-us" className="sidebar-button">
          About Us
        </a>
      </div>
    </aside>
  );
};

export default Sidebar;
